const getters = {
  sidebar: state => state.app.sidebar,
  // theme_custom: state => state.settings.theme_custom
  // visitedViews: state => state.tagsView.visitedViews,
  // cachedViews: state => state.tagsView.cachedViews,

  token: state => state.user.token,
  avatar: state => state.user.avatar,
  mobile: state => state.user.mobile,
  username: state => state.user.username,
  user_roles: state => state.user.user_roles,
  permission_routes: state => state.permission.routes,
  permissions: state => state.user.permissions,
  user_info:state=>state.user.user_info,
  team_rules:state=>state.user.team_rules,
};
export default getters
