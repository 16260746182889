import { ServeTranslate , getLangs } from "@/api/aimaterial";

export default {
  state: {
    //当前会话语言
    lang:"none",

    //语言集
    langs:[],
  },

  mutations: {

    // // 数组头部压入对话记录
    // SET_chat(state, records) {
    //   state.records = records
    // },
  },

  actions: {
      GET_LANGS_LIST({ commit , state }, dataNew) {
        console.info(state.langs,"state.langs----");
        if(state.langs.length){
          return new Promise((resolve, reject)=>{
            resolve(state.langs);
          });
        }
        return getLangs().then(res=>{
            let list = res.result;
            list.unshift({code:"none",name:"none",name_local:"无"});
            return state.langs = list;
        });
      },
      CHANGE_CONVERSION_LANGS({ commit , state }, lang ) {
        if("object"==typeof lang){
          //如果是尝试更改
          if(lang.try && state.lang){
            return;
          }
          lang = lang.lang;
        }
        return ServeEditConversation({id:state.conversation_id,lang:lang}).then(res=>{
           return  state.lang = lang;
        });
      },
      TRANSLATE_LANGS({ commit , state }, content ) {
        return ServeTranslate( "string"==typeof content?{text: content }:content ).then(res=>{
          return res;//.result.shift()
        });
      },
  }
}
