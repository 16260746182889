import Cookies from 'js-cookie';
import Vue from "vue";
const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  sidebarBackground: 'primary' //vue|blue|orange|green|red|primary
};

const mutations = {
  SET_SIDEBAR_BACKGROUND: (state, background) => {
    state.sidebarBackground = background;
  },
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  }
};

const actions = {
  setSidebarBackground({ commit }, background) {
    commit('SET_SIDEBAR_BACKGROUND', background);
  },
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device);
  },
  toggleMode({ commit }, isDark) {
    this.darkMode = isDark
    let docClasses = document.body.classList
    if (isDark) {
      docClasses.remove('white-content')
      docClasses.add('dark-mode')
      docClasses.remove('light-mode')
    } else {
      docClasses.add('white-content')
      docClasses.add('light-mode')
      docClasses.remove('dark-mode')
    }
  },
  initSetting({ commit,dispatch }, setting) {
    console.info(setting,"setting----------");
    if("undefined"!=typeof setting.darkMode){
      dispatch("toggleMode",setting.darkMode);
    }
    if("undefined"!=typeof setting.sidebarBackground){
      dispatch("setSidebarBackground",setting.sidebarBackground);
    }
    if("undefined"!=typeof setting.sidebarMini){
      // dispatch("closeSideBar",false);
      if(!setting.sidebarMini){
        Vue.prototype.$sidebar.toggleMinimize();
      }

    }

  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
