import { login, logout, getInfo , getTeamUsers } from '@/api/user';
import { getToken, setToken, removeToken , getUserId,setUserId,removeUserId,getTeamId,setTeamId,removeTeamId } from '@/utils/auth';
import WebSocketManager from "@/WebSocketClientManager";
// import { resetRouter } from '@/routes';
const state = {
  token: getToken(),
  user_id: getUserId(),
  mobile:"",
  username: '',
  avatar: '',
  user_info:{},
  team_info:null,
  user_roles:[],
  team_users:{},
  permissions: [],
  userPoint: 0
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERID: (state, ID) => {
    state.user_id = ID;
  },
  SET_NAME: (state, username) => {
    state.username = username;
  },
  SET_MOBILE: (state, mobile) => {
    state.mobile = mobile;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_team_info: (state, team_info ) => {
    state.team_info = team_info;
  },
  SET_user_roles: (state, user_roles ) => {
    state.user_roles = user_roles;
  },
  SET_USER_INFO: (state, user_info ) => {
    console.log("设置用户信息");
    console.log(user_info);
    state.user_info = user_info;
  },
  SET_TEAM_USERS: (state, teams ) => {
    state.team_users = teams;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_USER_POINT: (state, userPoint) => {
    state.userPoint = userPoint
  }
};

const actions = {
  // user login
  login({ commit , dispatch }, userInfo) {
    // const { mobile, password , smscode } = userInfo;
    let mobile = userInfo.phone||userInfo.email,password=userInfo.password,smscode=userInfo.code;
    return new Promise((resolve, reject) => {
      login({ mobile: mobile.trim(), password: password , smscode:smscode }).then(response => {
        const { token ,user  } = response;
        let accessToken = token;
        console.log(accessToken ,user);
        setToken( accessToken );
        // setUserId( user.id );
        // setTeamId( user.team_id );

        // //发送给插件
        // window.postMessage({
        //     type: "STAR_CHROM_PLUG_LOGIN",
        //     value: accessToken
        // },"*"),

        commit('SET_TOKEN', accessToken);
        // commit('SET_USERID', user.id);
        // commit('SET_NAME', user.username || user.mobile );
        // commit('SET_AVATAR', user.avatar);
        dispatch("getInfo");//获取信息

        resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  },

  // get user info
  getInfo({ commit, state , dispatch , store}) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {

        const data = response;
        if (!data.user) {
          // reject('验证失败.');
          return;
        }
        let result = data.user;

        const { userId:id,avatar,mobile,userName:username ,dept:team_info , roles:user_roles,systemSettings } = result;
        commit('SET_USERID', id);
        commit('SET_NAME', username || mobile );
        commit('SET_MOBILE', mobile );
        commit('SET_AVATAR', avatar);
        commit('SET_USER_INFO', result );
        commit('SET_team_info', team_info );
        commit('SET_user_roles', data.roles||["ttlive"]);//user_roles&&user_roles.length ? user_roles :["ttkol"] );
        commit('SET_PERMISSIONS', data.permissions);
        commit('SET_USER_POINT', data.userIntegral.point || 0 )

        // dispatch('permission/generateRoutes', user_roles, {root: true});//更新窗口 由permiss更新
        // dispatch('getTeams');//更新窗口

        //登录成功的事件//////////////////////////////////////////////
        window.postMessage({type:'tab_chromext_transfer',data:{
            type: "AIXNEW_KOL_LOGIN",
            data: state.token
        }},"*")

        WebSocketManager.getInstance();

        dispatch("app/initSetting",systemSettings, { root: true })
        //登录成功的事件end//////////////////////////////////////////////

        resolve(result);
      }).catch(error => {
        reject(error);
      });
    });
  },

  getTeams({ commit, state , dispatch , store }) {
    return new Promise((resolve, reject) => {
      getTeamUsers({pageSize:1000}).then(response => {

        const { result } = response;
        let list = {};
        result[0].forEach(item=>{
          list[item.id] = item;
        })
        commit('SET_TEAM_USERS', list );

        resolve(list);
      }).catch(error => {
        reject(error);
      });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '');
      commit('SET_USER_INFO', '');
      removeToken();
      removeTeamId();
      removeUserId();

      // resetRouter();

      resolve();
      // logout(state.token).then(() => {
      //   commit('SET_TOKEN', '');
      //   removeToken();
      //   resetRouter();
      //   resolve();
      // }).catch(error => {
      //   reject(error);
      // });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
