import DashboardLayout from './../pages/Starter/SampleLayout.vue';
import Starter from './../pages/Starter/SamplePage.vue';

import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component:  () =>
        import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () =>
        import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue'),
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: () =>
        import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue'),
    },
    {
      path: '/lock',
      name: 'Lock',
      component: () =>
        import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue'),
    }
  ]
};

const routes = [
    // {
    //   path: '/',
    //   name: 'home',
    //   redirect: '/dashboard',
    //   component: DashboardLayout,
    //   children: [
    //     {
    //       path: 'dashboard',
    //       name: 'dashboard',
    //       components: { default: Starter }
    //     }
    //   ]
    // },
    authPages,
    { path: '*', component: NotFound }
  ]

export default routes;
