<template>
  <div class="empty-div" :style="{ height: height, marginTop: mt, }">
    <svg aria-hidden="true">
      <use :xlink:href="icon"></use>
    </svg>
    <div class="btn-con">
      <el-button type="text" @click="jump">{{ text }}</el-button>
    </div>
  </div>
</template>


<script>
export default {
  name:"EmptyState",
  props: {
    icon: {
      type: String,
      default: '#icon-empty_5'
    },

    text: {
      type: String,
      default: '暂无数据'
    },

    url: {
      type: String,
      default: ''
    },

    height: {
      type: String,
      default: '100%'
    },
    mt: {
      type: String,
      default: '0'
    }
  },

  methods: {
    jump () {
      if (!this.url) return this.$router.go(-1);
      this.$router.push(this.url)
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  svg {
    width: 100%;
  }
  .btn-con {
    font-size: 12px;
    margin-top: 26px;
  }
}
</style>
