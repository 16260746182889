<template>
  <div>
    <el-tabs tab-position="left" >
        <el-tab-pane label="AI提词管理">
          <div class="row">
            <div class="col-md-12">
              <card>
                <template slot="header">
                  <h4 class="card-title">For Ai</h4>
                </template>
                <div>

                  <el-form ref="form" :model="raw" label-width="120px">

                    <el-form-item label="Ai App">
                      <el-select
                          v-model="raw.ai_id"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="选择AI应用"
                          :remote-method="remoteMethod"
                          :loading="loading">
                          <el-option
                            v-for="item in appList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="侧重风格">
                      <div class="row">
                        <div class="col-sm-3 checkbox-radios">
                            <base-radio v-model="raw.ai_reply_style" :name="'humorous'"
                              >幽默</base-radio
                            >
                        </div>
                        <div class="col-sm-3 checkbox-radios">
                            <base-radio v-model="raw.ai_reply_style" :name="'professional'"
                              >专业</base-radio
                            >
                        </div>
                        <div class="col-sm-3 checkbox-radios">
                            <base-radio v-model="raw.ai_reply_style" :name="'tricking'"
                              >整蛊</base-radio
                            >
                        </div>
                      </div>
                    </el-form-item>

<!--                    <el-form-item label="username">
                      <el-input placeholder="add with username" v-model="raw.aiapp_id"></el-input>
                    </el-form-item>

                    <el-form-item label="username">
                      <base-input placeholder="Enter email" />
                    </el-form-item> -->

                  </el-form>

                </div>
              </card>
            </div>
            <!-- end item -->
          </div>

        </el-tab-pane>
        <el-tab-pane label="弹幕规则">
          <div class="row">
            <div class="col-md-12">
              <card>
                <h4 slot="header" class="card-title">弹幕消息</h4>
                <el-form ref="form" :model="raw" label-width="120px">

                  <el-form-item label="显示信息">
                    <div class="row">

                      <div class="col-sm-5 checkbox-radios">
                          <base-radio v-model="raw.display_msg_barr" :name="1"
                            >弹幕信息</base-radio
                          >
                      </div>

                      <div class="col-sm-5 checkbox-radios">
                          <base-radio v-model="raw.display_msg_share" :name="1"
                            >分享信息</base-radio
                          >
                      </div>

                      <div class="col-sm-5 checkbox-radios">
                        <base-radio v-model="raw.display_msg_follow" :name="1"
                          >关注信息</base-radio
                        >
                      </div>

                      <div class="col-sm-5 checkbox-radios">
                        <base-radio v-model="raw.display_msg_gift" :name="1"
                          >礼物信息</base-radio
                        >
                      </div>

                      <div class="col-sm-5 checkbox-radios">
                        <base-radio v-model="raw.display_msg_member" :name="1"
                          >进入信息</base-radio
                        >
                      </div>
                    </div>
                  </el-form-item>

                  <el-form-item >
                        <template slot="label">
                          <el-tooltip  effect="dark" content="仅提示N级以上粉丝进入信息" placement="top-start">
                                 <span>进入级别</span>
                          </el-tooltip>
                        </template>
                        <el-slider
                          v-model="raw.display_member_level"
                          placeholder="进入显示"
                          show-input>
                        </el-slider>
                  </el-form-item>

                  <el-form-item >
                        <template slot="label">
                          <el-tooltip  effect="dark" content="礼物价值大于多少金币显示" placement="top-start">
                                 <span>礼物价值</span>
                          </el-tooltip>
                        </template>
                        <el-slider
                          v-model="raw.display_gift_coin"
                          show-input>
                        </el-slider>
                  </el-form-item>
                </el-form>

                  <!-- <div class="row">
                    <label class="col-sm-2 col-form-label"
                      >Custom Checkboxes &amp; radios</label
                    >
                    <div class="col-sm-4 col-sm-offset-1 checkbox-radios">
                      <base-checkbox>Unchecked</base-checkbox>
                      <base-checkbox :checked="true">Checked</base-checkbox>
                      <base-checkbox disabled>Disabled Checked</base-checkbox>
                      <base-checkbox disabled :checked="true"
                        >Disabled Checked</base-checkbox
                      >
                    </div>

                    <div class="col-sm-5 checkbox-radios">
                      <base-radio v-model="radios.radioOn" name="1"
                        >Radio is On</base-radio
                      >
                      <base-radio v-model="radios.radioOn" name="2"
                        >Radio is Off</base-radio
                      >

                      <base-radio v-model="radios.radioOff" name="1" disabled
                        >Radio is On</base-radio
                      >
                      <base-radio v-model="radios.radioOff" name="2" disabled
                        >Radio is Off</base-radio
                      >
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-sm-2 col-form-label">Input with success</label>
                    <div class="col-sm-10">
                      <base-input class="has-success" value="Success"></base-input>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-sm-2 col-form-label">Input with error</label>
                    <div class="col-sm-10">
                      <base-input class="has-danger" value="Error"></base-input>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-sm-2 col-form-label">Column sizing</label>

                    <div class="col-sm-10">
                      <div class="row">
                        <div class="col-md-3">
                          <base-input placeholder=".col-md-3"></base-input>
                        </div>

                        <div class="col-md-4">
                          <base-input placeholder=".col-md-4"></base-input>
                        </div>

                        <div class="col-md-5">
                          <base-input placeholder=".col-md-5"></base-input>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </form>
              </card>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <base-button class="mt-3" @click="submitSave" type="primary">save</base-button>
  </div>

</template>
<script>
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import {  getAppList } from "src/api/aigpt/app";
export default {
  components: {
    BaseCheckbox,
    BaseRadio
  },
  data() {
    return {
        raw:{
          "display_member_level": 0,
          "display_gift_coin": 0,
          "display_msg_follow": 1,
          "display_msg_member": 1,
          "display_msg_gift": 1,
          "display_msg_share": 1,
          "display_msg_barr": 1,
          "ai_reply_style": 1
      },

      radios: {
        radio1: '1',
        radio2: '2',
        radio3: '2',
        radioOn: '2',
        radioOff: '2'
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      //app
      appList:[],
      loading:true,
    };
  },
  created(){
    this.remoteMethod();
            console.info("res------------");
  },
  methods:{
    remoteMethod(query) {
            console.info("res------------");
        const _this = this;
        _this.loading = true;
        // getAppList({"parentId":"6672695b2ca7f4d1ec2d518d","searchKey":""}).then(res => {
        getAppList().then(res => {
            console.info(res);
            this.appList = res.map(item=>{
              return {
                ...item,
                id:item._id,
              };
            });
          })
          .finally(() => {
            _this.loading = false;
          });
    },
    submitSave(){
      console.info(JSON.stringify(this.raw) ,'--------');
      this.$store.dispatch("live/setPromptRule",{raw:this.raw});
    }
  }
};
</script>
<style></style>
