  // import { getPondCodes } from "@/api/ttpond";

const cacheopts  = {
  state: {

	  pondcodes: [],

  },
  mutations: {
    // 设置列表
    // SET_TALK_ITEM(state, resource) {
    //   state.items = resource.items
    // },

  },
	actions: {
		// GET_PONDCODES({ commit ,state }, dataNew) {
		// 	console.info(state.pondcodes);
  //     return new Promise((resolve, reject)=>{
  //       if(!dataNew && state.pondcodes && state.pondcodes.length){
  //       		resolve(state.pondcodes);
  //       }
  //       return getPondCodes(dataNew)
  //       	.then(({ code, result }) => {
  //       	   state.pondcodes = result.list;
  //            resolve(state.pondcodes);
  //       	});
  //     });


		// },

	}
}

export default cacheopts;
