import request from '@/utils/request';


export function getEventCnt(params) {
  return request({
    url: 'liveevent/cnt',
    method: 'get',
    params
  });
}

export function getUserRatio(params) {
  return request({
    url: 'liveevent/userRatio',
    method: 'get',
    params
  });
} 

// export function getLiveData(params) {
//   return request({
//     url: 'live/room/getLiveData',
//     method: 'get',
//     params
//   });
// }

// export function getMonitorRoom(params) {
//   return request({
//     url: 'live/room/refresh',
//     method: 'get',
//     params
//   });
// }

// // export function getAuthorDetails(params) {
// //   return request({
// //     url: '/live/author/details',
// //     method: 'get',
// //     params
// //   });
// // }

// export function createAuthor(data) {
//   return request({
//     url: '/live/author/create',
//     method: 'post',
//     data
//   });
// }


// export function getLiveDetails(params) {
//   return request({
//     url: 'live/room/details',
//     method: 'get',
//     params
//   });
// }

// export function getTopViewsrCnt(params) {
//   return request({
//     url: 'live/room/getTopViewsrCnt',
//     method: 'get',
//     params
//   });
// }

// export function getLiveStatistic(params) {
//   return request({
//     url: 'live/room/liveStatistic',
//     method: 'get',
//     params
//   });
// }

// export function getAuthorInfo(params) {
//   return request({
//     url: 'live/room/authorInfo',
//     method: 'get',
//     params
//   });
// }

// export function getLiveRecordsList () {

// }
