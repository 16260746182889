import request from '@/utils/request';

// export function getTeamUsers(params) {
//   return request({
//     url: '/sopcount',
//     params: params
//   });
// }

export function login(data) {
  data.username = data.mobile;
  return request({
    url: '/login',
    method: 'post',
    data
  });
}

export function sendSmsApi(data) {
  return request({
    url: '/auth/sendsms',
    method: 'post',
    data
  });
}

export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get',
    params: {}
  });
}

export function updateInfo(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}

export function joinTeam(data) {
  return request({
    url: '/user/team/join',
    method: 'post',
    data: data
  });
}
export function createTeam(data) {
  return request({
    url: '/user/team/create',
    method: 'post',
    data: data
  });
}

export function refreshTeam() {
  return request({
    url: '/user/team/i',
    method: 'get',
    params: {}
  });
}

export function updateTeamInfo(data) {
  return request({
    url: '/user_team/update',
    method: 'post',
    data
  });
}

// export function getTeamList() {
//   return request({
//     url: '/user_team/list'
//   });
// }
