<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />

    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
    <template slot="links____">
      <sidebar-item v-for="route in permission_routes" :key="route.path" v-if="route.meta&&!route.hidden"
       :link="{
         name: $t(route.name),
         icon: `tim-icons icon-${route.meta&&route.meta.icon?route.meta.icon:'chart-pie-36'}`,
         path: route.path
       }"
       >
         <sidebar-item
            v-for="subroute in route.children" :key="subroute.path" v-if="route.meta&&!subroute.hidden"
           :link="{ name: $t(subroute.name),
                    icon: `tim-icons icon-${route.meta&&route.meta.icon?route.meta.icon:'chart-pie-36'}`,
                    path: `${route.path}/${subroute.path}`
                  }"
         ></sidebar-item>
       </sidebar-item>
    </template>

    <!-- 自定义度高 -->
    <template slot="links">
        <!-- <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />-->

        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }"
        >
        </sidebar-item>

<!--       <sidebar-item
          :link="{
            name: $t('sidebar.ailive'),
            icon: 'tim-icons icon-user-run',
            path: '/ailive/panel'
          }"
        ></sidebar-item> -->

       <sidebar-item
          :link="{
            name: $t('sidebar.monitor'),
            icon: 'tim-icons icon-user-run',
            path: '/live/monitor'
          }"
        ></sidebar-item>


        <sidebar-item
          :link="{
            name: $t('sidebar.live_note'),
            icon: 'tim-icons icon-light-3',
            path: '/live/note'
          }"
        />

<!--       <sidebar-item
          :link="{
            name: $t('sidebar.live_analysis'),
            icon: 'tim-icons icon-light-3',
            path: '/live/review'
          }"
        ></sidebar-item> -->

        <sidebar-item
          :link="{
            name: $t('sidebar.live_list'),
            icon: 'tim-icons icon-spaceship',
            path: '/live'
          }"
        ></sidebar-item>



        <sidebar-item
          :link="{ name: $t('sidebar.ai'), icon: 'tim-icons icon-app' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.ai_app'), path: '/ai/app' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.ai_lib'), path: '/ai/lib' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.fans'),
            icon: 'tim-icons icon-coins',
            path: '/patron'
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.myaccount'),
            icon: 'tim-icons icon-coins',
            path: '/live/myaccount'
          }"
        ></sidebar-item>

        <template v-if="isDev">
        <el-divider></el-divider>
        <sidebar-item
          :link="{ name: $t('sidebar.pages'), icon: 'tim-icons icon-image-02' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.pricing'), path: '/pricing' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.rtl'), path: '/pages/rtl' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.timeline'), path: '/pages/timeline' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.login'), path: '/login' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.register'), path: '/register' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.lock'), path: '/lock' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.userProfile'), path: '/pages/user' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.components'),
            icon: 'tim-icons icon-molecule-40'
          }"
        >
          <sidebar-item :link="{ name: $t('sidebar.multiLevelCollapse') }">
            <sidebar-item
              :link="{
                name: $t('sidebar.example'),
                isRoute: false,
                path: 'https://google.com',
                target: '_blank'
              }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item
            :link="{ name: $t('sidebar.buttons'), path: '/components/buttons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.gridSystem'),
              path: '/components/grid-system'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.panels'), path: '/components/panels' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.sweetAlert'),
              path: '/components/sweet-alert'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.notifications'),
              path: '/components/notifications'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.icons'), path: '/components/icons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.typography'),
              path: '/components/typography'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.forms'), icon: 'tim-icons icon-notes' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.regularForms'), path: '/forms/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.extendedForms'),
              path: '/forms/extended'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.validationForms'),
              path: '/forms/validation'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.wizard'), path: '/forms/wizard' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.tables'),
            icon: 'tim-icons icon-puzzle-10'
          }"
        >
          <sidebar-item
            :link="{
              name: $t('sidebar.regularTables'),
              path: '/table-list/regular'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.extendedTables'),
              path: '/table-list/extended'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.paginatedTables'),
              path: '/table-list/paginated'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.maps'), icon: 'tim-icons icon-pin' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.googleMaps'), path: '/maps/google' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.fullScreenMaps'),
              path: '/maps/full-screen'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.vectorMaps'), path: '/maps/vector-map' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.widgets'),
            icon: 'tim-icons icon-settings',
            path: '/widgets'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.charts'),
            icon: 'tim-icons icon-chart-bar-32',
            path: '/charts'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.calendar'),
            icon: 'tim-icons icon-time-alarm',
            path: '/calendar'
          }"
        ></sidebar-item>

        </template>

      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share> -->

    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// import SidebarShare from './SidebarSharePlugin.vue';


function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { ZoomCenterTransition } from 'vue2-transitions';
import WebSocketManager from "@/WebSocketClientManager";
import { mapGetters } from 'vuex';
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    // SidebarShare
  },
  data() {
    return {
      // sidebarBackground: 'vue' ,//vue|blue|orange|green|red|primary
      // process:process,
      isDev:process.env.NODE_ENV=="development",
    };
  },
  computed: {
    ...mapGetters([
      "permission_routes",
      'sidebar'
    ]),
    sidebarBackground() {
      return this.$store.state.app.sidebarBackground
    }
  },
  mounted() {
    this.initScrollbar();

    // WebSocketManager.getInstance().addMessageListener(this.messageListener);
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////
  destroyed() {
    WebSocketManager.getInstance().removeMessageListener(this.messageListener);
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },

    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },

    //以上菜单相关//////////////////////////////////////////////////////////////////////////////


    messageListener (message) {
            // console.info('-------- 接收到socket下发的消息 ------', message.data)
          if (message.type === "live_event") {
            console.info('-------- 接收到socket下发的消息 ------', message.data)

            // // 当页面在 消息中心 || 在首页并且聊天 chat 是打开的，就不需要弹窗提示，直接同步聊天框聊天数据
            // if (this.$route.fullPath === '/contact' ) {
            //   this.$store.commit('contact/SELECTED_CARD', message.data)
            //   this.$store.dispatch('contact/getRecordListByContactId')
            //   return
            // }

            // if (this.$route.fullPath === '/dashboard' && this.influencerIsShowKolChat) {
            //   this.$store.commit('contact/SELECTED_CARD', message.data)
            //   this.$store.dispatch('contact/getRecordListByContactId')
            //   return
            // }

            // // 新消息 弹窗提示
            // this.newMsgNotify(message.data)
          }
      }

      ////////////////////////////////////////////////////////////////////////////////////////////////

  },

  watch: {
    sidebarBackgroundC (newVal) {
      console.log("🚀 ~ sidebarBackground ~ newVal:", newVal)
      this.sidebarBackground = newVal
    }
  }

};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
