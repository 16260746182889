const formatNumberYiAndWan = (value) =>  {
  if (value === null || value === undefined) {
    return '-'
  }

  value = Number(value)
  if (isNaN(value)) {
    return 0 + '万';
  }

  if (value >= 100000000) {
    return (value / 100000000).toFixed(2) + '亿'
  } else if (value >= 10000) {
    return (value / 10000).toFixed(2) + '万'
  } else {
    return value.toString()
  }
}


export {
  formatNumberYiAndWan
}
