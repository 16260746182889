import request from '@/utils/request';



// 获取应用列表
export function getAppList(params) {
  return request({
    url: 'core/app/list',
    method: 'get',
    params
  });
}

// 更新应用基本信息
export function appUpdate(id, data) {
  return request({
    url: `core/app/update?appId=${id}`,
    method: 'put',
    data
  });
}

// 删除应用
export function delApp(id, data = null) {
  return request({
    url: `core/app/del?appId=${id}`,
    method: 'delete',
    data
  });
}

// 获取 GPT 通用的一些数据
export function getInitData () {
  return request({
    url: 'common/system/getInitData',
    method: 'get',
  });
}

// 获取知识库 列表
export function getDatasetList (data) {
  return request({
    url: 'core/dataset/list',
    method: 'post',
    data
  });
}

// 创建知识库
export function createLore (data) {
  return request({
    url: 'core/dataset/create',
    method: 'post',
    data
  });
}

// 创建知识库
export function delLore (id) {
  return request({
    url: `core/dataset/delete?id=${id}`,
    method: 'delete',
  });
}

// 获取知识库 数据集
export function getLoreDataList (data) {
  return request({
    url: 'core/dataset/collection/list',
    method: 'post',
    data
  });
}

// 获取知识库 数据集
export function uploadLoreDataFile (data) {
  return request({
    url: 'common/file/upload',
    method: 'post',
    data
  });
}

export function createFileId (data) {
  return request({
    url: 'core/dataset/collection/create/fileId',
    method: 'post',
    data
  });
}

export function createFileIdDel (id) {
  return request({
    url: `core/dataset/collection/delete?id=${id}`,
    method: 'delete',
  });
}

export function getAppListPost (data) {
  return request({
    url: 'core/app/list',
    method: 'post',
    data
  });
}

// 创建应用
export function createApp (data) {
  return request({
    url: 'core/app/create',
    method: 'post',
    data
  })
}

export function publishApp (id, data) {
  return request({
    url: `core/app/version/publish?appId=${id}`,
    method: 'post',
    data
  });
}

// export function add(data) {
//   return request({
//     url: 'domain/add',
//     method: 'post',
//     data
//   });
// }

