import request from '@/utils/request';


// export function getPromptIndex(params) {
//   return request({
//     url: '/live/prompt/index',
//     method: 'get',
//     params
//   });
// }

export function getPrompt(params) {
  return request({
    url: '/live/prompt/detail',
    method: 'get',
    params
  });
}

export function createPrompt(data) {
  return request({
    url: '/live/prompt/create',
    method: 'post',
    data
  });
}
