<template>
  <div class="data-header">
    <div class="head-left pointer" @click="goBack">
      <span class="el-icon-arrow-left "></span>
    </div>
    <div class="flex-col">
      <div class="head-title text-center text-lg font-weight-700">Live stream status</div>
    </div>
    <div class="head-right">
      <div class="head-item" @click="dialog3={visible:true}"><span class="el-icon-s-tools pointer"></span></div>
      <div class="head-item">
        <!-- <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
          <button class="btn btn-dark dropdown-toggle" @click.prevent slot="title-container">
            English
          </button>
          <template>
            <b-dropdown-item href="#!">
              <span>中文</span>
            </b-dropdown-item>
            <b-dropdown-item href="#!">
              <span>English</span>
            </b-dropdown-item>
          </template>
        </base-dropdown> -->

      </div>
    </div>

  <el-dialog
      :title="'自定义规则'"
      v-if="dialog3&&dialog3.visible"
      :visible="true"
      @close="dialog3=null"
      width="80%"
    >
          <!-- <el-form ref="form" :model="dialog3.raw" label-width="120px">
            <el-form-item label="username">
              <el-input placeholder="add with username" v-model="dialog3.raw.uid"></el-input>
            </el-form-item>
            <el-form-item class="align-right">
              <el-button
                type="primary"
                @click="onSubmitSearch"
                >search
              </el-button>
              <el-button @click="dialog3=null">取消</el-button>
            </el-form-item>
          </el-form> -->
        <editRules />
    </el-dialog>


  </div>
</template>
<script>
  // import { DropdownMenu, DropdownItem, Dropdown ,Icon} from 'element-ui'
  import editRules from "./editRules.vue";
  export default {
    components: {
        // [Dropdown.name]: Dropdown,
        // [DropdownItem.name]: DropdownItem,
        // [DropdownMenu.name]: DropdownMenu,
        // [Icon.name]: Icon,
        editRules,
    },
    data() {
      return {
        dialog3:null,
      };
    },
    methods: {
      goBack() {
          this.$router.go(-1);
        }
    },
    mounted() {
    }
  };
</script>
<style scoped>
  .data-header{
    display: flex;
    align-items: center;
    padding: 0 24px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 212;
    height: 80px;
    background: #211F30;
    color: #ffffff;
  }
  .head-right{
    display: flex;
    align-items: center;
  }
  .head-item{
    margin-left: 24px;
  }

</style>
