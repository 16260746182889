import Cookies from 'js-cookie';

const TokenKey = 'STAR_ADMIN_DASHBOARD_TOKEN';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  // localStorage.setItem("kolxnew_token",token);
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getUserId() {
  return Cookies.get("UserId");
}
export function setUserId(uid) {
  return Cookies.set("UserId", uid);
}
export function removeUserId() {
  return Cookies.remove("UserId");
}

export function getTeamId() {
  return Cookies.get("TeamId");
}
export function setTeamId(TeamId) {
  return Cookies.set("TeamId", TeamId);
}
export function removeTeamId() {
  return Cookies.remove("TeamId");
}
