import request from '@/utils/request';

export function getLangs(params) {
  return request({
    url: '/api/aimaterial/langs',
    method: 'get',
    params
  });
}


// 翻译服务接口
export const ServeTranslate = params => {
	// console.info(data);
  return request({
    url: '/api/aimaterial/translate',
    method: 'get',
    params
  });
}
