/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.4.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import store from './store';
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";

// 全局过滤器
import * as filters from "@/filters";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 全局过滤器 end！

import './permission' // permission control

import "./assets/css/css.css";
import "./assets/iconfont/iconfont.css";
import "./assets/sass/index.scss";

// router setup
import router from "./routes/router";
import i18n from "./i18n";
import "./registerServiceWorker";
// import "./recordRoom";
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './assets/theme/element/index.css'
// import './assets/sass/element-variables.scss'
Vue.use(ElementUI);

import {EmptyState} from "@/components";
Vue.component(EmptyState.name,EmptyState)

import Contextmenu from "@/components/Contextmenu";
Vue.use(Contextmenu)


import * as ECharts from 'echarts';
Vue.prototype.$echarts = ECharts;


Vue.prototype.parseTime = parseTime

// import WebSocketManager from "@/WebSocketClientManager";
// WebSocketManager.getInstance()

// Define global method
Vue.prototype.goBack = ()=>{
  vm.$router.go(-1);
  // window.history.back();
};

Vue.prototype.$loading = {
  start:(title,text)=>{
    console.info("start_loading");
    Swal.fire({
        title: title||'Loading...',
        html: text,//||'Please wait ',
        imageUrl: '/img/icons/loading.gif',
        imageHeight: 100,
        allowOutsideClick: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        // didOpen: () => {
        //     Swal.showLoading();
        // }
    });
  },
  stop:()=>{
      Swal.close();
  }
}

Vue.prototype.$store = store;
/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  store,
  router,
  i18n,
});
