import defaultSettings from '@/settings';
const { showSettings, fixedHeader, sidebarLogo , tagsView  ,  title , theme_custom } = defaultSettings;
// import variables from '@/styles/element-variables.scss'
// import custom_theme from '@/assets/custom-theme/index.css' // the theme changed version element-ui css

const state = {
  theme_custom:theme_custom,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  appTitle: title
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
