import { getMonitorRoom } from "@/api/ailive/live"
import { getPrompt, createPrompt } from "@/api/ailive/prompt"
import { getEventCnt } from "@/api/ailive/liveevent"

// import {getAuthor} from "@/api/ailive/author"

let _commit, _state, _dispatch;
import WebSocketManager from "@/WebSocketClientManager";
import permission from "./permission";
const messageListener = async (message) => {
  // console.info('-------- 接收到socket下发的消息 ------', message.data)
  if (message.type != "live_event") { return; }

  const type = message.data.type, event = message.data.data || message.data;
  // console.log("🚀 ~ messageListener ~ event eventeventeventeventevent event:", event)
  // console.info('-------- 接收到socket下发的event ------',type)

  let { room_id, author_id, raw } = event || {};
  // console.log("🚀 ~ messageListener ---------------------- ~ raw:", raw)
  if (!raw) {
    return;
  }

  // if (!_state.roomInfo){
  //   _state.status = raw.status;
  // }

  let { displayType, msgId, actionId, user: userRaw, viewerCount, content: msg } = raw.raw || {};
  // console.log("🚀 ~ messageListener ~ userRaw:", userRaw)

  let user;
  if (userRaw?.userId) {
    // console.info('-------- 接收到socket下发的event ------',raw.badgeList?.length,raw.nickname,type, event)
    _state.authors[userRaw.userId] = user = {
      id: userRaw.userId,
      unique_id: userRaw.uniqueId,
      nickname: userRaw.nickname,
      sec_uid: userRaw.secUid,
      avatar: userRaw.profilePictureUrl,
      is_subscriber: userRaw.isSubscriber,
      // state: raw.,
      // language: raw.,
      // region: raw.,
      // signature: raw.,
      following_count: userRaw?.followInfo?.followingCount,
      follower_count: userRaw?.followInfo?.followerCount,
    };
  }


  if (type == "room_info" || type == "enter_room") {
    _state.roomInfo = event;
    _state.status = _state.roomInfo.state;
  }
  // 进入事件，云端以10秒钟的刻度去筛选出要提示的达人
  else if (type == "member") {
    // console.log("🚀 ~ messageListener ~ user:", '进入直播间 -------------------------', user)

    _commit("SET_USER_RATIO", event.ratio)

    if (user?.is_subscriber) {
      _commit("ADD_CHATS_FANS", {
        unique_id: user?.unique_id,
        uid: user.id,
        content: `进入直播间`,
      });
    } else {
      _commit("ADD_CHATS_NO_FANS", {
        unique_id: user?.unique_id,
        uid: user.id,
        // content: `${user.nickname || user?.uniqueId || user?.unique_id} 进入直播间`,
        content: `进入直播间`,
      });
    }

    // _commit("ADD_CHAT",{
    //   unique_id:user?.unique_id,
    //   uid:user.id,
    //   content: `${user.nickname || user?.uniqueId || user?.unique_id} 进入直播间`,
    // });
    // _commit("ADD_MEMBER",{
    //   event.raw
    // });
  }
  // else if(type=="member"){
  //   _commit("ADD_MEMBER",{
  //     event.raw
  //   });
  // }
  //消息以id为毽，直接获取新的就好
  else if (type == "chat") {
    console.log("🚀 ~ messageListener ~ user:", '聊天聊天 -------------------------', user)

    _commit("ADD_CHAT", {
      nickname: user?.nickname,
      unique_id: user?.unique_id,
      uid: user.id,
      content: msg,
    });
  }
  //喜欢、关注、分享等统计事项都在云端完成.
  // else if(type=="like"){
  //   _commit("ADD_EVENT",{
  //     event.raw
  //   });
  // }
  // else if(type=="like"){
  //   _commit("ADD_EVENT",{
  //     event.raw
  //   });
  // }
  else if (type == "streamEnd") {
    _state.status = 4;
  }
  else {

  }
  //弹幕AI回复
  //事件like share统计该达人的总数和分钟数的次数排序
  //检查用户进入是否需要提示，同时可以点击查看用户卡

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const state = {
  userRatio: {
    newUserRatio: '0.00%',
    oldUserRatio: '0.00%',
  },
  current_live: null,
  prompt:{},//提词规则
  roomInfo: null,
  status: -1, //4状态
  authors: {},
  members: [],
  chats: [],
  events: {},
  chatsFans: [
    // {
    //   "unique_id": "mohammadjadallah11",
    //   "uid": "7270918904125752325",
    //   "content": "(◠‿・)—☆MrtytrytryMAD 进入直播间"
    // },
    // {
    //   "unique_id": "ronaldo_2013pro",
    //   "uid": "6892684179067896833",
    //   "content": "sdfdf 进入直播间"
    // }
  ],
  chatsNoFans: [
    // {
    //   "unique_id": "mohammadjadallah11",
    //   "uid": "7270918904125752325",
    //   "content": "(◠‿・)—☆MØHAMMAD 进入直播间"
    // },
    // {
    //   "unique_id": "ronaldo_2013pro",
    //   "uid": "6892684179067896833",
    //   "content": "ғᴀɴs'ʀᴏɴᴀʟᴅᴏ 进入直播间"
    // }
  ],
};

const mutations = {
  SET_USER_RATIO: (state, data) => {
    state.userRatio = data;
  },
  SET_ROOM_INFO: (state, data) => {
    state.roomInfo = data;
  },
  ADD_MEMBER: (state, data) => {
    state.members.unshift(data);
  },
  ADD_CHAT: (state, data) => {
    state.chats.unshift(data);
    console.info(state.chats,"state.chats");
  },
  ADD_CHATS_FANS: (state, data) => {
    state.chatsFans.unshift(data);
  },
  ADD_CHATS_NO_FANS: (state, data) => {
    state.chatsNoFans.unshift(data);
  },
};

const actions = {
  setPromptRule({ commit, state }, data) {
    if (data) {
      return createPrompt(Object.assign(state.current_live, state.prompt, data));
    }
  },
  async getPromptInfo({ commit, state, dispatch }, data) {
    if (!_commit) {
      _commit = commit, _state = state, _dispatch = dispatch;
      WebSocketManager.getInstance().addMessageListener(messageListener);
    }

    try{
      let {live,prompt} =  await getPrompt(data);
      // console.info({live,prompt},"res--------");
      state.current_live = {unique_id: live.unique_id, author_id: live.author_id, room_id: live.id};
      state.roomInfo = live;
      state.status = live.state;
      state.prompt = prompt;
      return live;
    }catch(e){
      state.status = 4;
    }
  },
  syncLiveEvent({ commit, state, dispatch }) {
    if (!state.current_live) { return; }
    // console.info(data);
    WebSocketManager.getInstance().sendMessage({ type: "sync_live_event", data:state.current_live });
    // state.current_live = data;
  },



};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
