import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/routes/router'
import { getToken } from '@/utils/auth'
import Swal from 'sweetalert2'


// create an axios instance
const service = axios.create({
  baseURL: "/api",//process.env.VUE_APP_BASE_API, // url = base url + request url'http://180.76.36.87:9999',//
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 150000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    console.log("🚀 ~ config:", config)
    // do something before request is sent

    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = getToken()
    // }

    const isToken = (config.headers || {}).isToken === false
    let token = store.getters.token;
    if (token && !isToken) {
      // todo 会影响 fastGPT 的接口
      config.headers['Authorization'] = 'Bearer ' + token // token

      // console.log(config.headers['Authorization'])
    }

    // console.log(config)

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    const status = Number(response.status) || 200
    const message = response.data.msg

    // console.info(res);
    // if the custom code is not 20000, it is judged as an error.

    if(response.data.code === 402){
       store.state.user.showPriceModal =true;
    }
    if ([401].indexOf(response.data.code)>-1) {
      // token失效 ,重新获取tokene
      Swal.fire({
        title: 'error',
        text: '登录过期，请重新登录',
        icon: 'error',
        confirmButtonText: 'ok'
      });

      router.push({ path: '/login' });
      store.dispatch('FedLogOut');
      return;
    }
    else if (response.data.code !== 200) {
      let message = response.data.msg||"";

      let patt1 = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      let finalMessage = patt1.test(message) ? message : "响应错误请重试";
      Swal.fire({
        title: '错误',
        text: finalMessage,
        icon: 'error',
        confirmButtonText: '好的'
      });

      // return Promise.reject(new Error(message || 'Error'))
    }
    return res.data||res.result;
  },
  (error,b) => {
    console.log('---------- 请求错误：：', error)
    console.info(b);
    let {response} = error;
    response = response || {};
    if (response.status === 401) {
      // token失效 ,重新获取tokene
      Swal.fire({
        title: 'error',
        text: '登录过期，请重新登录',
        icon: 'error',
        confirmButtonText: 'ok'
      });

      router.push({ path: '/login' });
      // store.dispatch('FedLogOut');
      return;
    }
    else {
       Swal.fire({
          title: 'Error',
          text:  (response.data?.msg&&!response.data.msg.match(/(sql|QueryFailed)/gi))?response.data.msg:"稍后再试" ,//response.data.msg||response.statusText || 'Error',
          icon: 'error',
          confirmButtonText: 'comfire'
        });
      return;
    }
    return Promise.reject(error)
  }
)

export default service
