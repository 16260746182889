import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// import customRouter from './custom'
import routes from './routes';
import starterRouter from './starterRouter';


/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = routes; //注册所有合并的路由, 以前者为优先

/**
 * 静态路由
 */
export const constantRoutes = starterRouter;

const createRouter = () => new Router({
  // mode: 'history', // require service support
  routes: constantRoutes,
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router










// import VueRouter from 'vue-router';
// import routes from './routes';

// // configure router
// const router = new VueRouter({
//   routes, // short for routes: routes
//   linkActiveClass: 'active',
//   scrollBehavior (to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition
//     } else {
//       return { x: 0, y: 0 }
//     }
//   }
// });

// export default router;












