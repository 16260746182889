import { getToken, getUserId, getTeamId } from '@/utils/auth';
const wshost = process.env.VUE_APP_BASE_WS || "ws://localhost:8088";

export default class WebSocketManager {
  static instance = null;

  constructor() {
    this.messageListeners = [];
    this.connectStatusListeners = [];
    this.connect();
  }

  static getInstance() {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = new WebSocketManager();
    }
    return WebSocketManager.instance;
  }

  connect() {
    console.log('WebSocketManager 正在连接 -> ' + wshost);
    this.ws = new WebSocket(`${wshost}/aiws?type=transfer_api&token=${getToken()}&uid=${getUserId()}&team_id=${getTeamId()}`);

    this.ws.onopen = () => {
      console.log('WebSocketManager 连接成功！');
      this.connectStatusListeners.forEach((listener) => {
        listener('connect');
      });
    };

    this.ws.onmessage = (e) => {
      this.messageListeners.forEach((listener) => {
        listener(JSON.parse(e.data));
      });
    };

    this.ws.onclose = () => {
      console.warn('WebSocketManager 连接断开！5s后自动重连');
      this.connectStatusListeners.forEach((listener) => {
        listener('close');
      });
      setTimeout(() => {
        this.connect();
      }, 5 * 1000);
    };

    this.ws.onerror = (err) => {
      console.error(err);
      this.connectStatusListeners.forEach((listener) => {
        listener('error');
      });
    };
  }

  async waitState(){
    const _this = this;
    return new Promise((resolve, reject) => {
      let __i = 20;
      let si = setInterval(function(){
        __i  -=1;
        if(_this.ws.readyState==1){
          clearInterval(si);
          resolve(true);
        }
        if(__i<1){
          clearInterval(si);
          reject("超时");
        }
      },500);
    })
  }

  async sendMessage(msg,type) {
    // this.ws.send(JSON.stringify(msg));
    await this.waitState();
    this.ws.send(JSON.stringify({event:"transfer_server",data:type?{type:type,data:msg}:msg}));
  }

  addMessageListener(listener) {
    this.messageListeners.push(listener);
  }

  removeMessageListener(listener) {
    let i = this.messageListeners.length;
    while (i--) {
      if (this.messageListeners[i] === listener) {
        this.messageListeners.splice(i, 1);
      }
    }
  }

  addConnectStatusListener(listener) {
    this.connectStatusListeners.push(listener);
  }

  removeConnectStatusListener(listener) {
    let i = this.connectStatusListeners.length;
    while (i--) {
      if (this.connectStatusListeners[i] === listener) {
        this.connectStatusListeners.splice(i, 1);
      }
    }
  }
}
