<template>
  <div class="data-page">
    <dataHead></dataHead>
    <div class="data-wrapper">
      <div class="data-aside">
        <div class="data-panel" style="height: 352px;">
          <div class="panel-body">
            <div class="panel-head">
              <div class="btn btn-dark">Popularize</div>
              <!-- <span class="font_family icon-fangda pointer"></span> -->
              <i class="tim-icons icon-tv-2"></i>
            </div>
            <div class="dc-cells">
              <div class="dc-cell">
                <img src="../../assets/images/dc1.png" alt="">
                <div class="flex-col">
                  <div class="text-sm text-gray">Number of barrages</div>
                  <div class="text-lg font-weight-600">19,833,70</div>
                </div>
              </div>
              <div class="dc-cell">
                <img src="../../assets/images/dc2.png" alt="">
                <div class="flex-col">
                  <div class="text-sm text-gray">Number of interactors</div>
                  <div class="text-lg font-weight-600">19,833,70</div>
                </div>
              </div>
              <div class="dc-cell">
                <img src="../../assets/images/dc3.png" alt="">
                <div class="flex-col">
                  <div class="text-sm text-gray">Total number of viewers</div>
                  <div class="text-lg font-weight-600">19,833,70</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-panel" style="height: 258px;">
          <div class="panel-body">
            <div class="panel-head">
              <div class="btn btn-dark">Traffic source</div>
              <span class="font_family icon-fangda pointer"></span>
            </div>
            <div class="data-echart" id="echart1" ref="echart1" ></div>
          </div>
        </div>
        <div class="data-panel" style="height: 294px;">
          <div class="panel-body">
            <div class="panel-head">
              <div class="btn btn-dark">User portraits</div>
              <span class="font_family icon-fangda pointer"></span>
            </div>
            <div class="echart-top mb-2">Gender</div>
            <div class="data-echart" id="echart2" ref="echart2" ></div>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div class="echart-panel" style="height: 352px;">
          <div class="ec-panel-hd">
            <div class="tt">Teleprompter  (AI)</div>
            <div class="item"><span class="el-icon-s-tools"></span></div>
          </div>
          <div class="ec-panel-bd">
            <p>issue: Can you share this with me?</p>
            <p>Reply: Would you love to travel to Korea but hate planning trips? That's why I'm here. Whetheryou're traveling solo, on a family vacation, honeymoon, or with a large grou</p>
            <p>I've been to 28 countries, lived abroad, traveled on a budget and in luxury, and s..</p>
          </div>
          <div class="ec-panel-ft">
            <button class="btn btn-ec">
              <span class="font_family icon-shuaxin"></span><span>Rebuild</span>
            </button>
            <button class="btn btn-ec">
              <span class="font_family icon-telegram"></span><span>Reply to the barrage</span>
            </button>
          </div>
        </div>
        <div class="data-panel" style="height: 576px;">
          <div class="panel-body">
            <div class="panel-head">
              <div class="panel-tit font-weight-700">List of barrages</div>
            </div>
            <div class="barr-cells barr-hasdot">
              <div class="barr-cell" v-for="(item,index) in lists" :key="index">
                <div class="barr-avatar">
                  <img :src="item.img" alt="">
                </div>
                <div class="flex-col">
                  <div class="barr-title">
                    <span class="mr-3">{{item.title}}</span><span class="time">12:43</span>
                  </div>
                  <div class="barr-desc">Can you share this with me?</div>
                </div>
                <div class="barr-icons">
                  <span class="font_family icon-dh"></span>
                  <span class="font_family icon-lianxi2hebing_jinzhi"></span>
                  <span class="font_family icon-star-k"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="data-aside">
        <div class="data-panel" style="height: 352px;">
          <div class="panel-body">
            <div class="panel-head">
              <div class="btn btn-dark">Popularity trends</div>
              <span class="font_family icon-fangda pointer"></span>
            </div>
            <div class="data-echart" id="echart3" ref="echart3" ></div>
          </div>
        </div>
        <div class="data-panel" style="height: 576px;">
          <div class="panel-body">
            <div class="panel-head">
              <div class="panel-tabs">
                <div class="tab-item pointer" @click="tab(0)" :class="cur==0?'active':''">Live events</div>
                <div class="tab-item pointer" @click="tab(1)" :class="cur==1?'active':''">Live broadcast</div>
              </div>
              <span class="font_family icon-fangda pointer"></span>
            </div>
            <div class="pane" v-if="cur == 0">
              <div class="barr-cells ">
                <div class="barr-cell" v-for="(item,index) in lists2" :key="index">
                  <div class="barr-avatar">
                    <img :src="item.img" alt="">
                  </div>
                  <div class="flex-col">
                    <div class="barr-title">
                      <span class="mr-3">{{item.title}}</span><span class="time">12:43</span>
                    </div>
                    <div class="barr-desc">Can you share this with me?</div>
                  </div>
                  <div class="barr-icons">
                    <span class="font_family icon-dh"></span>
                    <span class="font_family icon-lianxi2hebing_jinzhi"></span>
                    <span class="font_family icon-star-k"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pane" v-if="cur == 1">2</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from "echarts"
  // import echarts from "echarts"
  import dataHead from './components/Head.vue';
  export default {
    components: {
      dataHead
    },
    data() {
      return {
        cur:0,
        lists:[
          {
            img:require('../../assets/images/touxiag.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/pic2.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/touxiag.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/pic2.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/pic2.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/pic2.png'),
            title:'Polar bear'
          }
        ],lists2:[
          {
            img:require('../../assets/images/touxiag.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/touxiag.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/touxiag.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/touxiag.png'),
            title:'Polar bear'
          },{
            img:require('../../assets/images/touxiag.png'),
            title:'Polar bear'
          }
        ]
      };
    },
    mounted() {
      console.info(this,"--------");
      this.$nextTick(() => {
        this.echartFa();
        this.echartFb();
        this.echartFc();
      });
      this.echartFa();
      this.echartFb();
      this.echartFc();
    },
    methods: {
      tab(i){
        this.cur = i
      },
      echartFa(){
          const chartData = [
            {
              value: 800,
              name: "Feed streams",
            },
            {
              value: 200,
              name: "Live Square",
            },
            {
              value: 300,
              name: "Other",
            },
            {
              value: 400,
              name: "Message",
            },
          ];
          const color = ['#6539DA', '#51E3C2', '#E35191', '#E38651',]
          const sum = chartData.reduce((per, cur) => per + cur.value, 0);
          var echart = this.$echarts.init(document.getElementById('echart1'));
          echart.setOption({
            background:'#fff',
                title: {
                  show: true,
                  text: "Percentage ",
                  subtext: "of traffic",
                  x: "20%",
                  y: "35%",
                  textStyle: {
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: "400",
                  },
                  subtextStyle: {
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: "400",
                  },
                  textAlign: "center",
                },
                legend: {
                  orient: "vertical",
                  // icon: "diamond",
                  textStyle: {
                    color: "#373737",
                    fontSize: "12px",
                    rich: {
                      name: {
                        color: 'rgba(255,255,255,.7)',
                        fontSize: 14,
                        width:100,
                        fontWeight: 400,
                        padding:[0,0,0,2]
                      },
                      symbol:{
                        color: '#fff',
                        fontSize: 14,
                        fontWeight: 400,
                        padding:[0,0,0,4]
                      },
                      percent: {
                        color: '#fff',
                        fontSize: 14,
                        fontWeight: 400,
                        padding:[0,0,0,4]
                      },
                      value:{
                        color: '#fff',
                        fontSize: 14,
                        fontWeight: 400,
                        padding:[0,0,0,10]
                      }
                    }
                  },
                  top: "center",
                  right: "0",
                  itemGap: 14,
                  itemHeight: 10,
                  itemWidth: 10,
                  data: chartData.name,
                  formatter: function(name){
                    let obj = {};
                    if (name){
                      obj = chartData.find(item=>item.name === name)
                    }
                    return `{name|${name}}{symbol| }{percent|${obj.value && (obj.value / sum * 100).toFixed(0)}%}`
                  }
                },
                series: [
                  {
                    name: "",
                    type: "pie",
                    roundCap: true,
                    radius: ["70%", "80%"],
                    center: ["20%", "50%"],
                    startAngle: 180, //起始角度
                    data: chartData,
                    itemStyle: {
                      borderColor: '#fff',
                      borderWidth: 0,
                      color: function (params) {
                        return color[params.dataIndex]
                      },
                    },
                    labelLine: {
                      length: 8,
                      length2: 16,
                      lineStyle: {
                        width: 1,
                      },
                    },
                    label: {
                      show: false,
                      fontFamily: "ysbth",
                      position: "outside",
                      padding: [0, -4, 0, -4],
                      formatter(params) {
                        if (params.name === "") {
                          return "";
                        }
                        return `${params.percent.toFixed(0)}%`;
                      },
                      color: "#fff",
                      fontSize: "14px",
                      lineHeight: 10,
                    },
                  },
                ],
          }),
          window.addEventListener('resize', () => {
              // 自动渲染echarts
              echart.resize();
          })
      },
      echartFb(){
          var fourPieData = [
            {
              name: "Man",
              max: 3500,
              value: 1185,
            },
            {
              name: "Women",
              max: 3200,
              value: 2011,
            },
          ];
          var titleArr = [],
            seriesArr = [];
          let colors = [
            ["#6051E3", "#323042"],
            ["#EA7C74", "#323042"],
          ];
          fourPieData.forEach(function (item, index) {
            titleArr.push({
              text: "",
            });
            seriesArr.push({
              name: item.name,
              type: "pie",
              clockWise: false,
              radius: [65, 70],
              itemStyle: {
                normal: {
                  color: colors[index][0],
                  shadowColor: colors[index][0],
                  shadowBlur: 0,
                  label: {
                    show: true,
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              hoverAnimation: false,
              center: [index < 2 ? index * 50 + 25 + "%" : index * 50 - 75 + "%", index < 2 ? "50%" : "70%"],
              data: [
                {
                  value: item.value,
                  name: "占比",
                  label: {
                    normal: {
                      formatter: function (params) {
                        let num = Math.floor((item.value / item.max) * 100);
                        return ["{a|" + num + "%}", "{b|" + item.name + "}"].join("\n");
                      },
                      rich: {
                        a: {
                          color: "#ffffff",
                          fontWeight: 500,
                          fontSize: 20,
                          lineHeight: 40,
                          align: "center",
                        },
                        b: {
                          color: "#CFCED2",
                          fontSize: 14,
                        },
                      },
                      position: "center",
                      show: true,
                      textStyle: {
                        fontSize: "20",
                        fontWeight: "bold",
                        color: colors[index][0], //跟随其他颜色
                      },
                    },
                  },
                  itemStyle: {
                    emphasis: {
                      color: colors[index][0],
                    },
                  },
                },
                {
                  value: item.max - item.value,
                  name: "总额",
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  itemStyle: {
                    normal: {
                      color: colors[index][1],
                    },
                  },
                },
              ],
            });
          });
          var echart = this.$echarts.init(document.getElementById('echart2'));
          echart.setOption({
            title: titleArr,
            series: seriesArr,
          }),
          window.addEventListener('resize', () => {
              // 自动渲染echarts
              echart.resize();
          })
      },
      echartFc(){
          var echart = this.$echarts.init(document.getElementById('echart3'));
          echart.setOption({
              color: ['#6051E3', '#52E3C2','#E35192'],
              tooltip: {
                  trigger: 'axis',
                  backgroundColor: 'rgba(13, 64, 71, 0.50)',
                  borderColor: 'rgba(143, 225, 252, 0.60)',
                  padding: 8,
                  textStyle: {
                      color: '#fff',
                  }
              },
              legend: {
                  data: ['Number', 'enter','leave'],
                  icon: 'circle',
                  itemWidth: 10,
                  itemHeight: 10,
                  top:0,
                  left:0,
                  textStyle: {
                      fontSize: 14,
                      color: '#ffffff'
                  }
              },
              grid: {
                  left: '2%',
                  right: '2%',
                  bottom: '2%',
                  top:'15%',
                  containLabel: true
              },
              xAxis: {
                  type: 'category',
                  axisLine: {                     // 轴线设置
                      show: false,                   // 显示轴线
                  },
                  axisLabel: {
                      textStyle: {
                          color: '#CFCED2',  //更改坐标轴文字颜色
                          fontSize: 12     //更改坐标轴文字大小
                      }
                  },
                  data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月']
              },
              yAxis: {
                  name:'(万元)',
                  nameTextStyle :{
                      fontSize:14
                  },
                  splitLine: {
                      show: true,
                      lineStyle: {
                          color: '#737A85',
                      },
                  },
                  axisLabel: {
                      textStyle: {
                          color: '#CFCED2',  //更改坐标轴文字颜色
                          fontSize: 12     //更改坐标轴文字大小
                      }
                  },
              },
              series: [
                  {
                      name: 'Number',
                      type: 'line',
                      // smooth: true,
                      symbol: 'circle',
                      symbolSize: 5,
                      label: {
                          show: false,
                          position: 'right',
                          color: '#fff'
                      },
                      areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                             {
                                  offset: 0,
                                  color: 'RGBA(96, 81, 227, .6)'
                              },
                              {
                                  offset: 1,
                                  color: 'RGBA(96, 81, 227, 0)'
                              }
                          ])
                      },
                      emphasis: {
                          focus: 'series'
                      },
                      data: [100, 132, 122, 134, 190, 185,201, 220]
                  },
                  {
                      name: 'enter',
                      type: 'line',
                      // smooth: true,
                      symbol: 'circle',
                      symbolSize: 5,
                      label: {
                          show: false,
                          position: 'right',
                          color: '#fff'
                      },
                      areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                  offset: 0,
                                  color: 'RGBA(82, 227, 194, .6)'
                              },
                              {
                                  offset: 1,
                                  color: 'RGBA(82, 227, 194, 0)'
                              }
                          ])
                      },
                      emphasis: {
                          focus: 'series'
                      },
                      data: [80, 162, 91, 134, 144, 130, 210, 120]
                  },
                   {
                      name: 'leave',
                      type: 'line',
                      // smooth: true,
                      symbol: 'circle',
                      symbolSize: 5,
                      label: {
                          show: false,
                          position: 'right',
                          color: '#fff'
                      },
                      areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                             {
                                  offset: 0,
                                  color: 'RGBA(227, 81, 146, .6)'
                              },
                              {
                                  offset: 1,
                                  color: 'RGBA(227, 81, 146, 0)'
                              }
                          ])
                      },
                      emphasis: {
                          focus: 'series'
                      },
                      data: [180, 162, 201, 74, 144, 130, 280, 220]
                  },
              ]
          }),
          window.addEventListener('resize', () => {
              // 自动渲染echarts
              echart.resize();
          })
      }
    }
  };
</script>
<style scoped>
  .barr-icons{
    .font_family{
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .barr-cells{
    margin-top: -14px;
    .barr-title{
      .time{
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #62606C;
      }
    }
    .barr-desc{
      font-size: 15px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #62606C;
    }
  }
  .barr-cell{
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid #2F2D3E;
    display: flex;
    align-items: center;
    transition: .3s all;
    .barr-avatar{
      font-size: 0;
      margin-right: 15px;
      img{
        width: 52px;
        height: 52px;
        border-radius: 44px;
      }
    }

    &:hover{
      background: #2F2D3E;
      padding: 14px 24px;
      margin: 0 -24px;
    }
  }
  .barr-hasdot{
    .barr-cell{
      &::after{
        content: '';
        width: 12px;
        height: 12px;
        background: #5DE352;
        border-radius: 2px 2px 2px 2px;
        position: absolute;
        left: -24px;
        top: 50%;
        margin-top: -6px;
        display: none;
      }
      &:hover{
        background: #2F2D3E;
        padding: 14px 24px 14px 48px;
        margin: 0 -24px;
      }
      &:hover::after{
        left: 24px;
        display: block;
      }
    }
  }
  .echart-panel{
    background: linear-gradient(225deg, #D474BC 0%, #9058FF 0%, #6558FF 51%, #6558FF 86%, #7F59FE 100%);
    border-radius: 10px 10px 10px 10px;
    color: #ffffff;
    padding: 24px;
    margin-bottom: 20px;
    .ec-panel-hd{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      border-bottom: 1px solid #FFFFFF;
      padding-bottom: 20px;
    }
  }
  .ec-panel-ft{
    margin-top: 40px;
    .btn-ec,
    .btn-ec:hover{
      height: 40px;
      background: #390DB8;
      padding: 0 15px;
      color: #ffffff;
      border-radius: 10px 10px 10px 10px;
    }
    .btn-ec .font_family{
      margin-right: 10px;
    }
  }
  .panel-tabs{
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 12px;
    background: #100A21;
    .tab-item{
      border-radius: 12px;
      height: 32px;
      line-height: 32px;
      padding: 0 12px;
    }
    .active{
      background: #211F30;
    }
  }
  .data-panel{
    background: #211F30;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 20px;
    .panel-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    .panel-body{
      padding: 24px;
      color: #ffffff;
    }
  }
  .data-page{
    min-height: 100vh;
    background: #100A21;
    padding-top: 80px;
  }
  .data-wrapper{
    display: flex;
    align-items: center;
    padding: 24px;
    > .flex-col{
      padding: 0 24px;
    }
  }
  .data-aside{
    width: 400px;
  }
  .dc-cell{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    img{
      width: 54px;
      height: 54px;
      margin-right: 15px;
    }
  }
  .data-echart{
    height: 140px;
  }
  #echart3{
    height: 240px;
  }






</style>
